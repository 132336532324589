@import "styles/colors.module.scss";
@import "styles/sizes.module.scss";

@keyframes bounce {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
}

.animation {
  animation: bounce 1.5s infinite;
}
