@import "styles/colors.module.scss";
@import "styles/sizes.module.scss";

.container {
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;

  .image {
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    height: 100%;
    width: 100%;

    @media (min-width: SmallScreen()) {
      object-position: center;
    }

    @media (min-width: BigScreen()) {
      object-position: center;
    }
  }
}
