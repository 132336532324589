@import "styles/colors.module.scss";
@import "styles/sizes.module.scss";

.view {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .item {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    box-sizing: border-box;
    overflow: hidden;
    padding-bottom: 20%;
    position: relative;

    @media (max-width: 768px) {
      width: 50%;
      padding-bottom: 50%;
    }

    .button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      filter: contrast(70%);
      transition: all 250ms ease-in-out;

      .circle {
        position: absolute;
        transform: translate(-50%, -50%) scale(0);
        height: 50px;
        width: 50px;
        top: 50%;
        left: 50%;
        background-color: #75fbe9;
        padding: 16px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 50px;
        transition: transform 250ms ease-in-out;
      }

      &:hover {
        filter: contrast(100%);
        transform: scale(1.2);

        .circle {
          transform: translate(-50%, -50%);
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      padding: 32px;
      box-sizing: border-box;
    }
  }
}
