@import "styles/colors.module.scss";
@import "styles/sizes.module.scss";

.view {
  position: relative;

  .illustration {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 160px;
  }

  .networks {
    position: relative;
    display: flex;
    gap: Grid(3);
    margin-bottom: Grid(3);
  }

  .author {
    position: relative;
  }
}
